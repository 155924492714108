import React, { useState } from 'react';
import { useLazyQuery, useMutation, gql } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import '../styles/loginPage.css';

const INITIATE_AUTH = gql`
  query initiateAuth($username: AWSEmail!, $password: String!) {
    initiateAuth(username: $username, password: $password) {
      access_token
      refresh_token
    }
  }
`;

const RESET_PASSWORD_MUTATION = gql`
  mutation resetPassword($username: AWSEmail!, $verification_code: String!, $new_password: String!) {
    resetPassword(username: $username, verification_code: $verification_code, new_password: $new_password) {
      result
    }
  }
`;

const PasswordSetupPage = () => {
  const history = useHistory();
  const location = useLocation();

  const [username, setUsername] = useState(location.state?.email || '');
  const [temporaryPassword, setTemporaryPassword] = useState(location.state?.password || '');
  const [newPassword, setNewPassword] = useState('');
  const [error, setError] = useState(null); // Error state for managing errors

  const [initiateAuth] = useLazyQuery(INITIATE_AUTH, {
    onError: (error) => {
      console.error('Initiate Auth Error:', error);
      const message = error.message || '';
      const codeMatch = message.match(/verification_code\s*=\s*'([^']+)'/);
      if (message.includes('New password required') && codeMatch) {
        const verificationCode = codeMatch[1];
        resetPasswordMutation(verificationCode);
      } else {
        setError('Failed to authenticate. Please check your credentials.');
      }
    },
  });

  const [resetPassword, { loading: resettingPassword }] = useMutation(RESET_PASSWORD_MUTATION, {
    onCompleted: () => {
      history.push('/login');
    },
    onError: (error) => {
      console.error('Reset Password Error:', error);
      setError('Failed to reset password. Please try again.');
    },
  });

  const resetPasswordMutation = (verificationCode) => {
    if (newPassword.length < 8) {
      setError('New password must be at least 8 characters long.');
      return;
    }
    setError(null); // Reset error before attempting the mutation
    resetPassword({
      variables: {
        username,
        verification_code: verificationCode,
        new_password: newPassword,
      },
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setError(null); // Reset error state before each new submit
    initiateAuth({
      variables: {
        username,
        password: temporaryPassword,
      },
    });
  };

  return (
    <div className="login-page">
      <Helmet>
        <title>Umps Device List - Password Setup</title>
      </Helmet>
      <div className="login-container">
        <h1>Set Up New Password</h1>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Email:</label>
            <input
              type="email"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>Temporary Password:</label>
            <input
              type="password"
              value={temporaryPassword}
              onChange={(e) => setTemporaryPassword(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>New Password:</label>
            <input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
            {newPassword.length > 0 && newPassword.length < 8 && (
              <p className="error-text">Password must be at least 8 characters long.</p>
            )}
          </div>
          <button type="submit" disabled={resettingPassword}>
            {resettingPassword ? 'Setting Password...' : 'Set New Password'}
          </button>
          {error && <p className="error-text">{error}</p>}
        </form>
      </div>
    </div>
  );
};

export default PasswordSetupPage;
