// src/pages/DeviceListPage.js

import React, { useEffect, useState, useMemo } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { logout } from '../utils/auth';
import { useTable, useSortBy, useFilters } from 'react-table';
import '../styles/deviceListPage.css';
import { LIST_SYSTEMS } from '../graphql/queries/listSystems';
import { formatDistanceToNow } from 'date-fns';

const DefaultColumnFilter = ({
  column: { filterValue, preFilteredRows, setFilter },
}) => {
  return (
    <input
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Filter...`}
      onClick={(e) => e.stopPropagation()} // Prevent sorting when clicking the filter input
    />
  );
};

const DeviceListPage = () => {
  const history = useHistory();
  const [systems, setSystems] = useState([]);
  const [lastFetchTime, setLastFetchTime] = useState(null); // New state for last fetch time
  const [fetchSystems] = useLazyQuery(LIST_SYSTEMS, {
    fetchPolicy: 'network-only',
  });
  const [loadingMessage, setLoadingMessage] = useState('Loading...');

  useEffect(() => {
    const fetchAllSystems = async () => {
      let allSystems = [];
      let nextToken = null;

      try {
        do {
          const result = await fetchSystems({ variables: { limit: 500, nextToken } });

          if (result.errors) {
            console.error('GraphQL Errors:', result.errors);
          }

          if (result.data && result.data.listSystems) {
            const validSystems = result.data.listSystems.items.filter((system, index) => {
              if (result.errors) {
                const errorPaths = result.errors.map(err => err.path.join('.'));
                return !errorPaths.includes(`listSystems.items.${index}`);
              }
              const systemState = system.basic_info?.system_status?.system_state;
              return systemState && systemState.trim() !== '';
            });

            allSystems = [...allSystems, ...validSystems];
            nextToken = result.data.listSystems.nextToken;

            // Update last fetch time in user's local time
            // Manually format fetchTime to "YYYY-MM-DD HH:MM"
			const fetchTime = new Date();
			setLastFetchTime(fetchTime);
			const formattedTime = 
			  `${fetchTime.getFullYear()}-${String(fetchTime.getMonth() + 1).padStart(2, '0')}-${String(fetchTime.getDate()).padStart(2, '0')} ` +
			  `${String(fetchTime.getHours()).padStart(2, '0')}:${String(fetchTime.getMinutes()).padStart(2, '0')}`;

			// Updated message format
			setLoadingMessage(`${allSystems.length} systems loaded - last updated ${formattedTime}`);

          } else if (!result.data) {
            nextToken = null;
          }
        } while (nextToken);

        setSystems(allSystems);
      } catch (e) {
        console.error('Fetching systems failed:', e);
        if (e.networkError && e.networkError.statusCode === 401) {
          logout();
        }
      }
    };

    fetchAllSystems();
  }, [fetchSystems]);

const columns = useMemo(() => [
  {
    Header: 'System ID',
    accessor: 'system_id',
    Filter: DefaultColumnFilter,
    Cell: ({ row }) => (
      <span>{row.original.system_id}</span>
    ),
  },
  {
    Header: 'PERS ID',
    accessor: 'configuration.pers_id',
    Filter: DefaultColumnFilter,
    Cell: ({ value }) => (
      <div style={{ minWidth: '80px', maxWidth: '80px', whiteSpace: 'nowrap' }}>
        {value}
      </div>
    ),
  },
  {
    Header: 'Provider',
    accessor: 'advanced_info.organisation_setting.pers_provider',
    Filter: DefaultColumnFilter,
    Cell: ({ value }) => (
      <div style={{ minWidth: '100px', maxWidth: '100px', whiteSpace: 'nowrap' }}>
        {value}
      </div>
    ),
  },
  {
    Header: 'Region',
    accessor: 'advanced_info.organisation_setting.pers_region',
    Filter: DefaultColumnFilter,
    Cell: ({ value }) => (
      <div style={{ minWidth: '200px', maxWidth: '200px', whiteSpace: 'nowrap' }}>
        {value}
      </div>
    ),
  },
  {
    Header: 'State',
    accessor: row => {
      const value = row.basic_info?.connection_state;
      return value && value.toLowerCase() === 'connected' ? 'Online' : 'Offline';
    },
    Filter: DefaultColumnFilter,
    Cell: ({ value }) => (
      <div style={{ minWidth: '80px', maxWidth: '80px', whiteSpace: 'nowrap' }}>
        {value}
      </div>
    ),
  },
  {
    Header: 'Last State Change',
    accessor: row => {
      const items = row.advanced_info?.connectivity_events?.items;
      if (Array.isArray(items) && items.length > 0 && items[0]?.timestamp_utc) {
        return items[0].timestamp_utc;
      } else {
        return '';
      }
    },
       Cell: ({ value }) => {
      if (value) {
        const date = new Date(value);
        if (isNaN(date)) {
          return 'Invalid Date';
        }
        const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')} ` +
                              `${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`;
        const relativeTime = formatDistanceToNow(date, { addSuffix: true }); 

        return (
          <span title={relativeTime} style={{ cursor: 'pointer' }}> 
            {formattedDate}
          </span>
        );
      } else {
        return 'No Data';
      }
    },
    Filter: DefaultColumnFilter,
  },
  {
    Header: 'Uptime (7d)',
    accessor: 'basic_info.system_uptime',
    Cell: ({ value }) => {
      if (value !== null && value !== undefined) {
        const uptimePercentage = parseFloat(value);
        if (!isNaN(uptimePercentage)) {
          return `${uptimePercentage.toFixed(2)}%`;
        } else {
          return 'Invalid Uptime';
        }
      } else {
        return 'No Data';
      }
    },
    Filter: DefaultColumnFilter,
  },
    // Updated 'Average Signal (7d)' column
    {
      Header: 'Average Signal (7d)',

      accessor: row => {
        const events = row.advanced_info?.mobile_network_events?.items;
        if (Array.isArray(events) && events.length > 0) {
          let totalMinSignalPercentage = 0;
          let totalSignalPower = 0;
          let totalSignalQuality = 0;

          let countMinSignalPercentage = 0;
          let countSignalPower = 0;
          let countSignalQuality = 0;

          let numDataPoints = 0;

          events.forEach(event => {
            const signalPower = event.signal_power;
            const signalQuality = event.signal_quality;

            // Exclude data points where RSRP (signal_power) is < -150 or > 0
            // and where RSRQ (signal_quality) is < -20 or > 0
            const isValidSignalPower = typeof signalPower === 'number' && signalPower >= -150 && signalPower <= 0;
            const isValidSignalQuality = typeof signalQuality === 'number' && signalQuality >= -20 && signalQuality <= 0;

            // Only include valid data points in counts and totals
            if (isValidSignalPower) {
              totalSignalPower += signalPower;
              countSignalPower++;
            }

            if (isValidSignalQuality) {
              totalSignalQuality += signalQuality;
              countSignalQuality++;
            }

            // min_signal_percentage doesn't have specified exclusion criteria
            if (typeof event.min_signal_percentage === 'number') {
              totalMinSignalPercentage += event.min_signal_percentage;
              countMinSignalPercentage++;
            }

            // Increment numDataPoints if at least one of the measurements is valid
            if (isValidSignalPower || isValidSignalQuality || typeof event.min_signal_percentage === 'number') {
              numDataPoints++;
            }
          });

          if (numDataPoints === 0) {
            return null; // Return null for no valid data
          }

          const avgMinSignalPercentage = countMinSignalPercentage > 0 ? (totalMinSignalPercentage / countMinSignalPercentage) : null;
          const avgSignalPower = countSignalPower > 0 ? (totalSignalPower / countSignalPower) : null;
          const avgSignalQuality = countSignalQuality > 0 ? (totalSignalQuality / countSignalQuality) : null;

          return {
            percentage: avgMinSignalPercentage,
            rsrp: avgSignalPower,
            rsrq: avgSignalQuality,
            numDataPoints,
          };
        } else {
          return null;
        }
      },
      Cell: ({ value }) => (
        <div style={{ minWidth: '400px', maxWidth: '400px', whiteSpace: 'nowrap' }}>
          {value ? (
            (() => {
              const { percentage, rsrp, rsrq, numDataPoints } = value;
              const percentageStr = percentage !== null ? `${percentage.toFixed(1)}%` : 'N/A';
              const rsrpStr = rsrp !== null ? `${rsrp.toFixed(1)} dBm` : 'N/A';
              const rsrqStr = rsrq !== null ? `${rsrq.toFixed(1)} dB` : 'N/A';
              return `${percentageStr} (RSRP ${rsrpStr}, RSRQ ${rsrqStr}, ${numDataPoints} datapoints)`;
            })()
          ) : (
            'No data'
          )}
        </div>
      ),
      Filter: DefaultColumnFilter,
      sortType: (rowA, rowB) => {
        const a = rowA.values['Average Signal (7d)'];
        const b = rowB.values['Average Signal (7d)'];

        // Handle null or undefined values
        if (!a && !b) return 0;
        if (!a) return -1;
        if (!b) return 1;

        // Let's sort based on the percentage
        const aPercentage = a.percentage !== null ? a.percentage : -Infinity;
        const bPercentage = b.percentage !== null ? b.percentage : -Infinity;

        return aPercentage - bPercentage;
      },
      filter: 'text',
    },
  ], []);

  const filterTypes = useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const value = row.values[id];
          const { percentage, rsrp, rsrq, numDataPoints } = value || {};
          const valueStr = value ? `${percentage !== null ? percentage.toFixed(1) : 'N/A'}% (RSRP ${rsrp !== null ? rsrp.toFixed(1) : 'N/A'} dBm, RSRQ ${rsrq !== null ? rsrq.toFixed(1) : 'N/A'} dB, ${numDataPoints} datapoints)`: 'No data';
          return String(valueStr).toLowerCase().includes(String(filterValue).toLowerCase());
        });
      },
    }),
    []
  );

  const tableInstance = useTable(
    { columns, data: systems, filterTypes },
    useFilters,
    useSortBy
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  return (
    <div className="container">
      <Helmet>
        <title>Umps Device List</title>
      </Helmet>
      <div className="loading-message">
        {loadingMessage}
      </div>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th key={column.id} {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' ▼'
                        : ' ▲'
                      : ''}
                  </span>
                  <div>{column.canFilter ? column.render('Filter') : null}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <td key={cell.column.id} {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default DeviceListPage;
