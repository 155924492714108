import React, { useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { INITIATE_AUTH } from '../graphql/queries/initiateAuth';
import '../styles/loginPage.css';

const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [errorState, setErrorState] = useState(null); // New state for managing errors
  const history = useHistory();

  const [initiateAuth, { loading, error }] = useLazyQuery(INITIATE_AUTH, {
    onCompleted: (data) => {
      if (data && data.initiateAuth) {  // Check if initiateAuth data is available
        const { access_token, refresh_token } = data.initiateAuth;
        localStorage.setItem('access_token', access_token);
        localStorage.setItem('refresh_token', refresh_token);
        history.push('/devices');
      } else {
        setErrorState('Login failed. Please check your credentials.');
      }
    },
    onError: (error) => {
      const errorMessage = error.message || '';
      if (errorMessage.includes('New password required')) {
        const verificationCodeMatch = errorMessage.match(/verification_code = '([^']+)'/);
        const verificationCode = verificationCodeMatch ? verificationCodeMatch[1] : null;
        
        if (verificationCode) {
          history.push({
            pathname: '/password-setup',
            state: { email: username, temporaryPassword: password, verificationCode }
          });
        }
      } else {
        setErrorState('Login failed. Please check your credentials.');
      }
    }
  });

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setUsername(email);
    setIsEmailValid(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setErrorState(null); // Reset error state before each new login attempt
    if (isEmailValid) {
      initiateAuth({ variables: { username, password } });
    }
  };

  return (
    <div className="login-page">
      <Helmet>
        <title>Umps Device List - Login</title>
      </Helmet>
      <div className="login-container">
        <h1>Login</h1>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Email:</label>
            <input
              type="email"
              value={username}
              onChange={handleEmailChange}
              required
            />
            {!isEmailValid && <p className="error-text">Please enter a valid email address.</p>}
          </div>
          <div className="form-group">
            <label>Password:</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" disabled={loading}>
            {loading ? 'Logging in...' : 'Login'}
          </button>
          {errorState && <p className="error-text">{errorState}</p>}
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
