import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { REFRESH_TOKEN } from './queries/refreshToken';
import { Observable, ApolloLink } from '@apollo/client';
import fetch from 'cross-fetch';

const httpLink = createHttpLink({
  uri: 'https://api.umpslink.com/graphql',
  fetch,
});

const specialAuthLink = setContext((operation, previousContext) => {
  const specialAuthQueries = ['initiateAuth', 'refreshToken', 'resetPassword']; // Add resetPassword here
  const needsSpecialAuth = specialAuthQueries.some(query =>
    operation.query.definitions.some(definition => definition.name && definition.name.value === query)
  );

  const headers = previousContext?.headers || {};
  const authHeader = needsSpecialAuth ? 'umpslink-special-auth' : headers.authorization;

  console.log(`Setting specialAuthLink for ${needsSpecialAuth ? 'special auth' : 'regular auth'}`);
  console.log('Authorization Header:', authHeader);

  return {
    headers: {
      ...headers,
      authorization: authHeader,
      'Content-Type': 'application/json',
    }
  };
});

const authLink = setContext((_, { headers = {} }) => {
  const token = localStorage.getItem('access_token');
  console.log('Access Token:', token);

  // If the authorization header is already set (e.g., by specialAuthLink), do not overwrite it
  if (headers.authorization) {
    return {
      headers: {
        ...headers,
      }
    };
  }

  return {
    headers: {
      ...headers,
      authorization: token || headers.authorization,
    }
  };
});

const refreshLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    for (let err of graphQLErrors) {
      if (err.extensions && err.extensions.code === 'UNAUTHENTICATED') {
        const refreshToken = localStorage.getItem('refresh_token');
        console.log('Refresh Token:', refreshToken);
        if (refreshToken) {
          return new Observable(observer => {
            client
              .query({
                query: REFRESH_TOKEN,
                variables: { refresh_token: refreshToken }
              })
              .then(({ data }) => {
                const newAccessToken = data.refreshToken.access_token;
                console.log('New Access Token:', newAccessToken);
                localStorage.setItem('access_token', newAccessToken);
                operation.setContext({
                  headers: {
                    ...operation.getContext().headers,
                    authorization: newAccessToken,
                  },
                });
                const subscriber = {
                  next: observer.next.bind(observer),
                  error: observer.error.bind(observer),
                  complete: observer.complete.bind(observer),
                };
                forward(operation).subscribe(subscriber);
              })
              .catch(err => {
                observer.error(err);
              });
          });
        }
      }
    }
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

const client = new ApolloClient({
  link: ApolloLink.from([refreshLink, specialAuthLink, authLink, httpLink]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      errorPolicy: 'all'
    },
    query: {
      errorPolicy: 'all'
    }
  }
});

export default client;
