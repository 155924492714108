import { gql } from '@apollo/client';

export const INITIATE_AUTH = gql`
  query initiateAuth($username: AWSEmail!, $password: String!) {
    initiateAuth(username: $username, password: $password) {
      access_token
      refresh_token
    }
  }
`;
