// src/components/Layout.js
import React from 'react';
import { useLocation } from 'react-router-dom';
import Header from './Header';

const Layout = ({ children }) => {
  const location = useLocation();

  // Check if the current route is the login page
  const isLoginPage = location.pathname === '/login';

  // Determine the title based on the current route
  const getTitle = () => {
    if (location.pathname === '/devices') {
      return 'Device List';
    } else if (location.pathname.startsWith('/device')) {
      const params = new URLSearchParams(location.search);
      const systemId = params.get('system_id');
      return `System ${systemId}`;
    }
    return 'Umps Device List';
  };

  return (
    <div>
      {!isLoginPage && <Header title={getTitle()} />}
      <main>{children}</main>
    </div>
  );
};

export default Layout;
