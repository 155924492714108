import { gql } from '@apollo/client';

export const LIST_SYSTEMS = gql`
  query listSystems($limit: Int, $nextToken: String) {
    listSystems(limit: $limit, nextToken: $nextToken) {
      items {
		system_id,
		  basic_info {
			connection_state,
			system_status {
			  system_state
			},
			system_uptime(days:7),
		  },
		  configuration { 
			pers_id,      
		  },
		  advanced_info {
			organisation_setting{
			  pers_provider,
			  pers_region
			},
			connectivity_events(limit:5)
			{
			  items {
				connection_state,
				timestamp_utc			
			  }
			},
			mobile_network_events(days:7)
			{
			  items {
				signal_power,
				signal_quality,
				min_signal_percentage
			  }
			},
		}
      }
      nextToken
    }
  }
`;
