// src/components/Header.js
import React from 'react';
import { useHistory } from 'react-router-dom';
import umpsLogo from '../assets/umps-logo.png'; // Adjust the path if needed

const Header = ({ title }) => {
  const history = useHistory();

  const handleLogout = () => {
    // Clear tokens from localStorage or other storage mechanism
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    // Redirect to the login page
    history.push('/login');
  };

  return (
    <header className="header">
      <div className="header-left">
        <img src={umpsLogo} alt="Umps Link Logo" className="portal-logo" />
      </div>
      <div className="header-center">
        <h1 className="page-title">{title}</h1>
      </div>
      <div className="header-right">
        <button className="logout-button" onClick={handleLogout}>Logout</button>
      </div>
    </header>
  );
};

export default Header;
