// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import DeviceListPage from './pages/DeviceListPage';
import PasswordSetupPage from './pages/PasswordSetupPage'; // Import the new page
import Layout from './components/Layout';

const App = () => {
  const isAuthenticated = () => {
    return !!localStorage.getItem('access_token');
  };

  return (
    <Router>
      <Switch>
        {/* Public routes */}
        <Route path="/login" component={LoginPage} />
        <Route path="/password-setup" component={PasswordSetupPage} /> {/* New route */}

        {/* Protected routes */}
        <Layout>
          <Switch>
            <Route path="/devices" render={() => (
              isAuthenticated() ? <DeviceListPage /> : <Redirect to="/login" />
            )} />
            <Redirect from="/" to="/login" />
          </Switch>
        </Layout>
      </Switch>
    </Router>
  );
};

export default App;
